import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { IFocusError } from '../../../../components/formComponents/input'

enum RegisterParticipantTypeOfUser {
  Individual = 1,
  Facilitator = 2,
  Organization = 3,
}

const getTypeOfUsers = (languageText: ITranslationObject): IDropdownList[] => [
  {
    id: RegisterParticipantTypeOfUser.Individual,
    displayName: getLanguageValue(languageText, 'Individual'),
    value: RegisterParticipantTypeOfUser.Individual.toString(),
  },
  {
    id: RegisterParticipantTypeOfUser.Facilitator,
    displayName: getLanguageValue(languageText, 'Facilitator'),
    value: RegisterParticipantTypeOfUser.Facilitator.toString(),
  },
  {
    id: RegisterParticipantTypeOfUser.Organization,
    displayName: getLanguageValue(languageText, 'Organization'),
    value: RegisterParticipantTypeOfUser.Organization.toString(),
  },
]

enum RegisterParticipantInputs {
  name = 'name',
  surname = 'surname',
  emailAddress = 'emailAddress',
  password = 'password',
  confirmPassword = 'confirmPassword',
  country = 'country',
  company = 'company',
  plan = 'plan',
}

// this type looks identical to 'IRegisterParticipantAccountInfo' except
// that it has some optional keys for the social login. maybe we can
// de-copy-pasta it.
interface IRegisterParticipantPayload {
  name: string
  surname: string
  emailAddress: string
  password: string
  countryId: number
  company: string
  typeOfUser: number
  isSocialLogin: boolean
  authProvider: string
  providerAccessToken: string
  providerKey: string
  recaptchaToken: string
}

interface IRegisterParticipantAccountInfo {
  name: string
  surname: string
  emailAddress: string
  password: string
  confirmPassword: string
  country: string
  company: string
  plan: string
}

type IRegisterParticipantFocusInput = {
  [K in keyof IRegisterParticipantAccountInfo]: IFocusError
}

interface IRegisterParticipantResponse {
  isSocialLogin: boolean
  isRegistered: boolean
  verificationEmailSent: boolean
  isUserExists: boolean
}

export {
  RegisterParticipantTypeOfUser,
  RegisterParticipantInputs,
  IRegisterParticipantPayload,
  IRegisterParticipantFocusInput,
  IRegisterParticipantAccountInfo,
  IRegisterParticipantResponse,
  getTypeOfUsers,
}
