export enum AlertAction {
  None = 0,
  Dismiss,
  Reject,
  Accept,
}

export const ALERT_TRIGGERS = [
  'on_admin_login',
  'on_facilitator_login',
  'on_participant_login',
  'on_respondent_login',
  'on_login',
] as const

export type AlertTrigger = (typeof ALERT_TRIGGERS)[number]

export type IAlert = {
  id?: number
  trigger: AlertTrigger
  title: string
  body: string
  dateFrom?: string
  dateTo?: string
  isActive: boolean
  actionTaken: AlertAction
}
