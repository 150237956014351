import React from 'react'
import { useSelector } from 'react-redux'

import { ITranslationObject, getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { RootState } from '@app/store/configureStore'

const Spinner = (): JSX.Element => {
  // Handling Language
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  return (
    <div className='container'>
      <div className='position-fixed top-0 start-0 end-0 bottom-0 zindex-toast d-flex justify-content-center align-items-center bg-transparent'>
        <span className='spinner-border text-primary' role='status' />
      </div>
    </div>
  )
}

export default Spinner
