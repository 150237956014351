// This file includes helper functions for scroll

// Hide or Show body background scroll
export const hideBackgroundScroll = (hideScroll: boolean): void => {
  if (hideScroll) document.body.style.overflow = 'hidden'
  else document.body.style.overflow = 'auto'
}

// Open dropdown on top - helper fn , heightPercent ex : 0.75
export const setDropdownOnTop = (
  ref: React.RefObject<HTMLElement>,
  heightPercent: number = 0.8
): boolean => {
  const dropdownHeight = ref.current?.offsetHeight || 0
  const dropdownTop = ref.current?.getBoundingClientRect().top || 0
  const screenHeight = window.innerHeight
  const isDropdownBelowThreshold = dropdownTop + dropdownHeight > screenHeight * heightPercent

  return isDropdownBelowThreshold
}
