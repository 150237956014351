import { AnyAction, Dispatch } from 'redux'
import { api, apiEndPoints } from '../../../../api'
import { setNotification } from '../../../actions'
import { ISocialLoginData } from './hooks'
import { ApiResponse } from '@app/types'

export const getUserExternalLogins = async (
  token: string,
  dispatch: Dispatch
): Promise<string[]> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.get<ApiResponse<string[]>>(apiEndPoints.getUserExternalLogins, {
      headers: auth,
    })

    if (!response.data.success) {
      throw new Error(response.data.error?.message || 'Very bad error')
    }

    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const deleteUserExternalLogin = async (
  socialName: string,
  token: string,
  dispatch: Dispatch
): Promise<unknown> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  const paramQuery = {
    providerName: socialName,
  }
  try {
    const response = await api.delete(apiEndPoints.deleteUserExternalLogin, {
      headers: auth,
      params: paramQuery,
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const registerExternalLogin = async (
  dispatch: Dispatch<AnyAction>,
  socialLoginData: ISocialLoginData,
  token: string
): Promise<boolean> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }

  try {
    const response = await api.post<ApiResponse<unknown>>(
      apiEndPoints.registerExternalLogin,
      JSON.stringify(socialLoginData),
      {
        headers: auth,
      }
    )
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
