import * as Redux from 'redux'
import { SET_PRESENTATION_SLIDES, SET_PARTICIPANTS_LIST } from './constants'
import { IParticipants, IPresentationSlide } from './hooks'

type State = {
  presentationSlides: Array<IPresentationSlide>
  presenterToken: string
  participantsList: Array<IParticipants>
}

const initialState: State = {
  presentationSlides: [],
  presenterToken: '',
  participantsList: [],
}

const facilitatorDeliveryReducer: Redux.Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRESENTATION_SLIDES:
      return { ...state, presentationSlides: action.payload || [] }

    case SET_PARTICIPANTS_LIST:
      return { ...state, participantsList: action.payload || [] }

    default:
      return state
  }
}

export default facilitatorDeliveryReducer
