import { Dispatch } from 'redux'
import { api, apiEndPoints } from '../../api'
import { setNotification } from '../actions'
import { IVerifyEmailBody } from './hooks'
import { ApiResponse } from '../../types'

export const verifyEmail = async (
  urlParams: IVerifyEmailBody,
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    const response = await api.post<ApiResponse<boolean>>(
      apiEndPoints.verifyEmail,
      JSON.stringify(urlParams)
    )
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
  return false
}
