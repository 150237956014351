import {
  ACADEMY_COURSE_LANGUAGES,
  SELECTED_COURSE_LANGUAGE_ID,
  SET_COURSE_DETAILS,
} from './constant'
import { IAcademyCategories, IAcademyCoursesLanguages, ICourse } from './types'

export interface IInitialState {
  courseDetails: ICourse
  selectedCourseLanguageId: number
  academyCourseLanguages: IAcademyCoursesLanguages[]
}

const initialCategoryState: IAcademyCategories = {
  id: 0,
  parentId: 0,
  name: '',
  description: '',
  topic: '',
  coursesCount: 0,
  lessonsCount: 0,
  progress: 0,
}
const initialCourseState: ICourse = {
  id: 0,
  title: '',
  description: '',
  duration: 0,
  parts: 0,
  keyIdeas: 0,
  partsCompleted: 0,
  quizesCompleted: 0,
  courseParts: [],
  quizes: [],
  partsCount: 0,
  category: initialCategoryState,
  parentCategories: [],
  tags: '',
  parentCategoryId: 0,
  parentCategoryName: '',
  categoryId: 0,
  categoryName: '',
}
const initialState: IInitialState = {
  courseDetails: initialCourseState,
  selectedCourseLanguageId: 1, // Default english language id is 1
  academyCourseLanguages: [],
}

const academyReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_COURSE_DETAILS:
      return {
        ...state,
        courseDetails: action.payload,
      }
    case SELECTED_COURSE_LANGUAGE_ID:
      return {
        ...state,
        selectedCourseLanguageId: action.payload,
      }
    case ACADEMY_COURSE_LANGUAGES:
      return {
        ...state,
        academyCourseLanguages: action.payload,
      }
    default:
      return state
  }
}

export default academyReducer
