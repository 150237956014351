import * as React from 'react'
import PresentationHeader from '../facilitatorDelivery/Utils/presentationHeader'
import { IPresentationSlide, renderSlideHTML } from '../facilitatorDelivery/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@app/store/configureStore'
import { useNavigation, useParams, useSearchParams } from 'react-router-dom'
import { getAllPresentationSlides } from '../facilitatorDelivery/actions'
import { getCookieValue } from '../rootState'
import { BEARER_TOKEN } from '@app/constants'
import { PresentationSlideNotes } from './notes'

function noop(): void {}

const EMPTY_SLIDE: IPresentationSlide = {
  id: 0,
  description: '',
  html: '',
  slideNumber: 0,
  slideSteps: [],
  notes: '',
}

export const PreviewPresentationTemplate: React.FC<unknown> = (props) => {
  const token = getCookieValue(BEARER_TOKEN)
  const dispatch = useDispatch()
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const templateId = useParams<'id'>().id
  const slides = useSelector(
    (state: RootState) => state.facilitatorDeliveryReducer.presentationSlides
  )
  const [currentSlide, setCurrentSlide] = React.useState<IPresentationSlide>({ ...EMPTY_SLIDE })
  const [carouselSlideIndex, setCarouselSlideIndex] = React.useState<number>(0)
  const [slideStep, setSlideStep] = React.useState<number | null>(null)
  const [searchParams, setSearchParams] = useSearchParams({ slideNumber: '' })

  const previousSlide = (): void => {
    if (slideStep === 0) {
      setSlideStep(null)
      return
    }
    if (slides[slides.indexOf(currentSlide) - 1]?.slideSteps === null && slideStep !== 0) {
      setSlideStep(null)
    }
    if (slideStep !== 0 && slideStep !== null && slideStep >= 0) {
      setSlideStep(slideStep - 1)
      return
    }
    if (slides[slides.indexOf(currentSlide) - 1]?.slideSteps !== null) {
      setSlideStep(slides[slides.indexOf(currentSlide) - 1]?.slideSteps.length - 1)
    }

    if (carouselSlideIndex === 0) {
      return
    } else {
      setCurrentSlide(slides[carouselSlideIndex - 1])
      setCarouselSlideIndex(carouselSlideIndex - 1)
      setSearchParams({
        slideNumber: String(slides[carouselSlideIndex - 1].slideNumber),
      })
    }
  }

  const nextSlide = (): void => {
    const updatedSlideStep = Number(slideStep)

    if (currentSlide.slideSteps?.length > updatedSlideStep) {
      setSlideStep(null)
    }
    if (currentSlide.slideSteps !== null && slideStep === null) {
      setSlideStep(0)
      return
    }
    if (currentSlide.slideSteps !== null && currentSlide.slideSteps.length - 1 > updatedSlideStep) {
      setSlideStep(updatedSlideStep + 1)
      return
    }

    if (carouselSlideIndex === slides.length - 1) {
      return
    } else {
      setCurrentSlide(slides[carouselSlideIndex + 1])
      setCarouselSlideIndex(carouselSlideIndex + 1)
      setSearchParams({
        slideNumber: String(slides[carouselSlideIndex + 1].slideNumber),
      })
    }
  }

  const handleSlideClick = (slideNumber: number): void => {
    const index = slides.findIndex((slide) => slide.slideNumber === slideNumber)
    setCurrentSlide(slides[index])
    setCarouselSlideIndex(index)
    setSearchParams({
      slideNumber: String(slides[index].slideNumber),
    })
  }

  React.useEffect(() => {
    if (!templateId) {
      return
    }
    getAllPresentationSlides(Number(templateId), token, dispatch)
  }, [templateId])

  React.useEffect(() => {
    const slideNumber = searchParams.get('slideNumber')
    let wantedIndex = 0

    if (slideNumber) {
      wantedIndex = slides.findIndex((s) => s.slideNumber === parseInt(slideNumber, 10))
    }

    if (slides[wantedIndex]) {
      setCurrentSlide(slides[wantedIndex])
      setCarouselSlideIndex(wantedIndex)
    }
  }, [slides, searchParams])

  return (
    <React.Fragment>
      <PresentationHeader
        languageText={languageText}
        slideNumber={currentSlide.slideNumber}
        facilitatorSlides={slides}
        participants={[]}
        facilitatorName=''
        previousSlide={previousSlide}
        nextSlide={nextSlide}
        handleSlideClick={handleSlideClick}
        handleCancelClick={noop}
        openParticipantDynamicSlides={noop}
      />

      {renderSlideHTML(currentSlide, slideStep)}

      <PresentationSlideNotes languageText={languageText} slide={currentSlide} />
    </React.Fragment>
  )
}
