import axios from 'axios'
import { getBearerToken } from '../commonUtils/auth'
import { AUTH_HEADER_NAME, API_BASE_URL } from '../constants'
import { routePath } from '../containers/routePaths'

const createAPI = () => {
  const apiHeader = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }
  const api = axios.create({
    baseURL: API_BASE_URL,
    headers: apiHeader,
  })

  // Adding a response interceptor to check for 401 errors (Unauthorized)
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        const { pathname } = window.location
        if (pathname !== routePath.signIn && pathname !== routePath.participantLogIn) {
          localStorage.clear()
          sessionStorage.setItem('returnTo', window.location.pathname)
          sessionStorage.setItem('tokenExpired', 'true')
          window.location.href = routePath.signIn
        }
      }
      if (error?.response?.data?.error?.message) {
        return Promise.reject(error.response.data.error.message)
      } else if (error?.response?.data?.message) {
        return Promise.reject(error.response.data.message)
      } else {
        return Promise.reject(error.message)
      }
    }
  )

  // api.interceptors.request.use(async (config: any) => {
  //   const bearerToken = await getBearerToken();
  //   if (bearerToken) {
  //     config.headers[`${AUTH_HEADER_NAME}`] = bearerToken;
  //   }
  //   return config;
  // });

  return api
}

export default createAPI()
