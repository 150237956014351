import { api, apiEndPoints } from '@app/api'
import { ApiResponse, ApiResult } from '@app/types'
import { AlertAction, IAlert } from './types'
import { Dispatch } from 'redux'
import { setSpinner } from '../actions'
import { ITableCommonParams } from '../commonInterfaces'

export function getAlerts(
  token: string,
  dispatch: Dispatch,
  params: ITableCommonParams
): Promise<ApiResult<IAlert>> {
  dispatch(setSpinner(true))

  return api
    .get<ApiResponse<ApiResult<IAlert>>>(apiEndPoints.getAlerts, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
    })
    .then((res) => {
      if (!res.data.success) {
        return Promise.reject()
      }
      return res.data.result
    })
    .finally(() => {
      dispatch(setSpinner(false))
    })
}

export function saveAlert(token: string, dispatch: Dispatch, body: IAlert): Promise<IAlert> {
  const method = body.id ? 'PUT' : 'POST'
  const url = body.id ? apiEndPoints.updateAlert(body.id) : apiEndPoints.createAlert

  dispatch(setSpinner(true))

  return api<ApiResponse<IAlert>>({
    method: method,
    url: url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: body,
  })
    .then((res) => {
      if (!res.data.success) {
        return Promise.reject()
      }
      return res.data.result
    })
    .finally(() => {
      dispatch(setSpinner(false))
    })
}

export function deleteAlert(token: string, dispatch: Dispatch, id: number): Promise<unknown> {
  dispatch(setSpinner(true))

  return api
    .delete(apiEndPoints.deleteAlert(id), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .finally(() => {
      dispatch(setSpinner(false))
    })
}

export function takeAlertAction(
  token: string,
  dispatch: Dispatch,
  id: number,
  action: AlertAction
): Promise<unknown> {
  dispatch(setSpinner(true))

  return api
    .post(
      apiEndPoints.interactAlert(id),
      { actionTaken: action },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .finally(() => {
      dispatch(setSpinner(false))
    })
}

export function deleteUserInteractions(
  token: string,
  dispatch: Dispatch,
  id: number
): Promise<unknown> {
  dispatch(setSpinner(true))

  return api
    .delete(apiEndPoints.deleteUserInteractions(id), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .finally(() => {
      dispatch(setSpinner(false))
    })
}
