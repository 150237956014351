export const enum routePath {
  signIn = '/',
  register = '/register',
  registerParticipant = '/register/participant',
  registerFacilitator = '/register/facilitator',
  registerOrganization = '/register/organization',
  facilitatorDelivery = '/f/presentation',
  participantDelivery = '/p/presentation',
  createTemplate = '/createTemplate',
  editTemplate = '/editTemplate',
  createSlide = '/createSlide',
  editSlide = '/editSlide',
  presentationTemplateList = '/templatesList',
  upcomingPresentations = '/upcomingPresentations',
  previewPresentationTemplate = '/templatesList/:id/preview',
  slidesList = '/slidesList/:id',
  home = '/home',
  userProfile = '/user-profile',
  subscriptions = '/subscriptions',
  account = '/account',
  languages = '/languages',
  translations = '/translations',
  resetPassword = '/resetPassword',
  verifyEmail = '/verifyemail',
  employeeList = '/employeelist',
  editEmployee = '/employeelist/edit/:id',
  activities = '/activities',
  profileParticipants = '/profileParticipants',
  addProfile = '/profiles/add',
  editProfile = '/profiles/edit/:id',
  allWords = '/allWords',
  placement = '/placements',
  switchRoles = '/switchRoles',
  coursesList = '/coursesList',

  // Only Admin routes
  allfacilitators = '/allfacilitators',
  allparticipants = '/allparticipants',
  allaccounts = '/allaccounts',
  allactivities = '/allactivities',
  adminusers = '/adminusers',
  adminNewsList = '/adminnews',
  adminDocumentList = '/admindocuments',
  adminProfileDescriptions = '/adminprofiledescriptions',
  auditLogs = '/admin/auditlogs',
  adminEmailTemplates = '/admin/emailtemplates',
  adminLog = '/admin/log',
  adminAlerts = '/admin/alerts',

  //informations
  information = '/information',
  latestNews = '/latestnews',
  latestBlogs = '/latestblogs',
  latestDocument = '/latestDocuments',
  latestNewsPage = '/latestnews/:id',
  latestBlogPage = '/latestblogs/:id',
  // Participants routes
  dr = '/dr',
  verificationcode = '/verificationcode',
  participantLogIn = '/participant/login',
  participantChangePassword = '/participant/edit', // Change password / Edit participant details page
  participantCreate = '/participant/create', // Demographic page
  participantRole = '/participant/role',
  participantSelfForm = '/participant/selfForm',
  participantInviteRespondents = '/participant/inviteRespondents',
  participantProfilePresentation = '/participant/profilePresentation',
  participantWelcomePage = '/participant/welcome',
  participantDashboard = '/participant/dashboard',
  participantMyprofiles = '/participant/myprofiles',
  participantProfileReport = '/participant/myprofiles/profileReport/:profileParticipantId',
  participantUserProfile = '/participant/user-profile',
  participantAccountPage = '/participant/account',

  // Respondent routes (c -> repsondentCode)
  respondentLanguagePage = '/r/:c',
  respondentLandingPage = '/r/:c/startPage',
  respondentSelfForm = '/r/:c/selfForm',
  respondentThankYou = '/r/:c/thankYou',

  //Academy routes
  participantAcademy = '/participant/academy',
  participantCategoryCourses = '/participant/academy/categoryCourses/:parentId',
  participantCourseLessons = '/participant/academy/categoryCourses/lessons/:categoryId',
  participantCourses = '/participant/lessons',
  participantCourse = '/participant/lessons/:courseId/part/:partId',
  participantQuiz = '/participant/lessons/:courseId/quiz/:quizId',
  participantCourseSummary = '/participant/lessons/:courseId/summary',
}
