import * as Redux from 'redux'

import { IUserDetails, IUserProfile } from '@app/containers/commonInterfaces'
import {
  LOGGED_IN_USER_DATA,
  LOGGED_IN_USER_ID,
  LOGGED_IN_USER_DETAILS,
  SET_DEFAULT_CLIENT,
  PASSWORD_RESET_CODE,
  GET_SOCIAL_LOGIN_STATUS,
  CLEAR_DEFAULT_CLIENT,
  CLEAR_LOGGED_IN_USER_ROLE,
  SET_LOGGED_IN_USER_ROLE,
  SET_USER_LANGUAGE,
  SET_ACCESS_TOKEN_SCOPES,
} from './constants'
import { Roles } from '@app/containers/commonEnums'
import { AccessTokenScope } from './actions'
import { tryParseJson } from '@app/containers/utils'
import { LOCAL_STORAGE_KEYS } from '@app/constants'

export interface IDefaultClient {
  defaultClientId: number
  defaultClientName: string
}

export interface IUserLanguage {
  userLanguageCode: string
  userLanguageId: number
}

interface ILoginReducerState {
  userId: number
  userData: IUserProfile
  passwordResetCode: string
  userDetails: IUserDetails

  /** @deprecated 'defaultClient' should go away in favor of the current access token scope. */
  defaultClient: IDefaultClient
  loggedInUserRole: Roles | null
  userLanguage: IUserLanguage
  socialLoginStatus: Array<string>
  accessTokenScopes: Array<AccessTokenScope>
}

const accessTokenScopes = tryParseJson<Array<AccessTokenScope>>(
  window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_SCOPES),
  []
)

const initialState: ILoginReducerState = {
  userId: 0,
  userData: {
    name: '',
    surname: '',
    userName: '',
    emailAddress: '',
    phoneNumber: '',
    isPhoneNumberConfirmed: false,
    isTwoFactorEnabled: false,
    isEmailConfirmed: false,
    timezone: '',
    selectedLanguageId: null,
  },
  passwordResetCode: '',
  userDetails: {
    name: '',
    userLanguageCode: '',
    userLanguageDisplayName: '',
    userRoles: [],
    profileId: 0,
    profileParticipantId: 0,
    isOverviewPage: false,
    isConsultant: false,
    isProfileExists: false,
    enableParticipantCreateProfile: false,
  },
  defaultClient: {
    defaultClientId: 0,
    defaultClientName: '',
  },
  loggedInUserRole: null,
  userLanguage: {
    userLanguageCode: '',
    userLanguageId: 0,
  },
  socialLoginStatus: [],
  accessTokenScopes: accessTokenScopes,
}

const loginReducer: Redux.Reducer<ILoginReducerState> = (state = initialState, action) => {
  switch (action.type) {
    case GET_SOCIAL_LOGIN_STATUS:
      return {
        ...state,
        socialLoginStatus: action.payload,
      }
    case LOGGED_IN_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      }
    case LOGGED_IN_USER_ID:
      return {
        ...state,
        userId: action.payload,
      }
    case LOGGED_IN_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      }
    case SET_DEFAULT_CLIENT:
      return {
        ...state,
        defaultClient: action.payload,
      }
    case CLEAR_DEFAULT_CLIENT:
      return {
        ...state,
        defaultClient: {
          defaultClientId: 0,
          defaultClientName: '',
        },
      }
    case PASSWORD_RESET_CODE:
      return {
        ...state,
        passwordResetCode: action.payload,
      }
    case SET_LOGGED_IN_USER_ROLE:
      return {
        ...state,
        loggedInUserRole: action.payload,
      }
    case CLEAR_LOGGED_IN_USER_ROLE:
      return {
        ...state,
        loggedInUserRole: null,
      }
    case SET_USER_LANGUAGE:
      return {
        ...state,
        userLanguage: {
          userLanguageCode: action.payload.userLanguageCode,
          userLanguageId: action.payload.userLanguageId,
        },
      }
    case SET_ACCESS_TOKEN_SCOPES:
      return {
        ...state,
        accessTokenScopes: action.payload,
      }
    default:
      return state
  }
}
export default loginReducer
