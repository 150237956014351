import { Dispatch } from 'redux'

import { api, apiEndPoints } from '../../api'
import { setNotification } from '../actions'
import {
  CLEAR_PARTICIPANT_PROFILE_DETAILS,
  GET_EMAIL_CODE,
  PARTICIPANT_PROFILE_EXISTS,
  SET_PARTICIPANT_PROFILE_DETAILS,
} from './constants'
import { IPayload } from './profileParticipantPages/demographicPage.tsx/hooks'
import { IParticipantRoleData } from './profileParticipantPages/rolePage/hooks'
import { IUpdateParticipantFormLanguageBody } from './selectLanguagePage/hooks'
import {
  IRespondentList,
  IUserRespondentsBody,
} from './profileParticipantPages/inviteRespondents/hooks'
import { ISaveParticipantFieldValueBody } from '../profileList/addProfile/roleSettings/roleSettingsWithEditOption'
import {
  ICreateManualRespondentsBody,
  ICreateRespondentsParams,
  IRespondentEmailPreviewBody,
} from './profileParticipantPages/inviteRespondents/sendRespondentsInvitation'
import { IRespondentsInvitationBody } from './profileParticipantPages/inviteRespondents/resentRespondentInvite'
import {
  IFillParticipantProfileSelfFormBody,
  IFillProfileRespondentOtherFormBody,
} from '../../components/selfForm/selfForm'
import { setBearerToken } from '../../commonUtils/auth'
import { IParticipantProfileDetails } from './reducer'
import { IParticipantProfileResponse } from '@app/commonUtils/participantProfileHelper'
import {
  IClearParticipantProfileDetailsAction,
  IGetEmailCodeAction,
  ISetParticipantProfileDetailsAction,
  ISetParticipantProfileExists,
} from './interface'
import { AccessTokenScope, AuthenticateResult } from '../auth/signUp/actions'
import { ApiResponse } from '@app/types'

export const getEmailCode = (payload: string): IGetEmailCodeAction => ({
  type: GET_EMAIL_CODE,
  payload,
})

export const setParticipantProfileExists = (payload: boolean): ISetParticipantProfileExists => ({
  type: PARTICIPANT_PROFILE_EXISTS,
  payload,
})

export const setParticipantProfileDetails = (
  payload: IParticipantProfileDetails
): ISetParticipantProfileDetailsAction => ({
  type: SET_PARTICIPANT_PROFILE_DETAILS,
  payload,
})

export const clearParticipantProfileDetails = (): IClearParticipantProfileDetailsAction => ({
  type: CLEAR_PARTICIPANT_PROFILE_DETAILS,
  payload: undefined,
})

export const sendParticipantVerificationCodeEmail = async (
  uniqueCode: string,
  languageCode: string,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const endpointWithParams = `${apiEndPoints.sendParticipantVerificationCodeEmail}?uniqueCode=${uniqueCode}&languageCode=${languageCode}`
    const response = await api.post(endpointWithParams)
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

type AuthenticateVerificationLinkResult = {
  accessTokens: Array<AccessTokenScope>
  uniqueCode: string
  verificationCode: string
  expireInSeconds: number
}

export const verifyParticipantVerificationLink = async (
  verificationCode: string,
  dispatch: Dispatch
): Promise<AuthenticateVerificationLinkResult> => {
  try {
    const endpointWithParams = `${apiEndPoints.verifyParticipantVerificationLink}?c=${verificationCode}`
    const response = await api.post<ApiResponse<AuthenticateVerificationLinkResult>>(
      endpointWithParams
    )

    if (!response.data.success) {
      return await Promise.reject(response.data)
    }

    const accessToken = response.data.result.accessTokens[0]?.token
    const { expireInSeconds } = response?.data?.result
    setBearerToken(accessToken, expireInSeconds)
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const verifyParticipantVerificationCode = async (
  uniqueCode: string,
  verificationCode: string,
  dispatch: Dispatch
): Promise<ApiResponse<AuthenticateResult>> => {
  try {
    const endpointWithParams = `${apiEndPoints.verifyParticipantVerificationCode}?uniqueCode=${uniqueCode}&verificationCode=${verificationCode}`
    const response = await api.post<ApiResponse<AuthenticateResult>>(endpointWithParams)

    if (!response.data.success) {
      return await Promise.reject(response.data)
    }

    const accessToken = response.data.result.accessTokens[0]?.token
    const { expireInSeconds } = response?.data?.result
    setBearerToken(accessToken, expireInSeconds)
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const resetParticipantPassword = async (
  newPassword: string,
  token: string,
  dispatch: Dispatch
): Promise<boolean> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  const body = {
    newPassword: newPassword,
  }
  try {
    const response = await api.post(apiEndPoints.resetParticipantPassword, JSON.stringify(body), {
      headers: auth,
    })
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getParticipantFormInfo = async (
  uniqueCode: string,
  token: string,
  dispatch: Dispatch
): Promise<IParticipantProfileResponse> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.get(apiEndPoints.getParticipantFormInfo, {
      headers: auth,
      params: { uniqueCode },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const checkValidParticipantOrRespondentLink = async (
  uniqueCode: string,
  dispatch: Dispatch
): Promise<IParticipantProfileResponse> => {
  try {
    const endpointWithParams = `${apiEndPoints.checkValidParticipantOrRespondentLink}?uniqueCode=${uniqueCode}`
    const response = await api.post(endpointWithParams)
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const sendParticipantPasswordEmail = async (
  uniqueCode: string,
  languageCode: string,
  dispatch: Dispatch
): Promise<any> => {
  try {
    const endpointWithParams = `${apiEndPoints.sendParticipantPasswordEmail}?uniqueCode=${uniqueCode}&languageCode=${languageCode}`
    const response = await api.post(endpointWithParams)
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getProfileParticipantDemographicById = async (
  id: number,
  token: string,
  dispatch: Dispatch
): Promise<any> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.get(apiEndPoints.getProfileParticipantDemographicById, {
      headers: auth,
      params: { id },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getProfileParticipantRole = async (
  profileParticipantId: number,
  token: string,
  dispatch: Dispatch
): Promise<any> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.get(apiEndPoints.getProfileParticipantRole, {
      headers: auth,
      params: { profileParticipantId },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const updateParticipantRole = async (
  token: string,
  body: IParticipantRoleData,
  dispatch: Dispatch
): Promise<any> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.put(apiEndPoints.updateParticipantRole, JSON.stringify(body), {
      headers: auth,
    })
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const saveParticipantFieldValue = async (
  body: ISaveParticipantFieldValueBody,
  token: string,
  dispatch: Dispatch
): Promise<boolean> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.put(apiEndPoints.saveParticipantFieldValue, JSON.stringify(body), {
      headers: auth,
    })
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
  return false
}

export const updateParticipantDemographics = async (
  token: string,
  body: IPayload,
  dispatch: Dispatch
): Promise<any> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.put(
      apiEndPoints.updateParticipantDemographics,
      JSON.stringify(body),
      {
        headers: auth,
      }
    )
    return response
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const updateParticipantFormLanguage = async (
  body: IUpdateParticipantFormLanguageBody,
  dispatch: Dispatch
): Promise<boolean> => {
  try {
    const response = await api.put(
      apiEndPoints.updateParticipantFormLanguage,
      JSON.stringify(body),
      {
        params: body,
      }
    )
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const fillParticipantProfileSelfForm = async (
  body: IFillParticipantProfileSelfFormBody,
  token: string,
  dispatch: Dispatch
): Promise<boolean> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.post(
      apiEndPoints.fillParticipantProfileSelfForm,
      JSON.stringify(body),
      {
        headers: auth,
        params: body,
      }
    )
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const fillProfileRespondentOtherForm = async (
  body: IFillProfileRespondentOtherFormBody,
  token: string,
  dispatch: Dispatch
): Promise<boolean> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.post(
      apiEndPoints.fillProfileRespondentOtherForm,
      JSON.stringify(body),
      {
        headers: auth,
        params: body,
      }
    )
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getUserForRespondents = async (
  body: IUserRespondentsBody,
  token: string,
  dispatch: Dispatch
): Promise<IRespondentList[]> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.get(apiEndPoints.getUserForRespondents, {
      headers: auth,
      params: body,
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getRespondentEmailPreview = async (
  body: IRespondentEmailPreviewBody,
  token: string,
  dispatch: Dispatch
): Promise<any> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.get(apiEndPoints.getRespondentEmailPreview, {
      headers: auth,
      params: body,
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const createColleaguesAsProfileParticipantRespondents = async (
  body: Array<number>,
  params: ICreateRespondentsParams,
  token: string,
  dispatch: Dispatch
): Promise<any> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.post(
      apiEndPoints.createColleaguesAsProfileParticipantRespondents,
      JSON.stringify(body),
      {
        headers: auth,
        params: params,
      }
    )
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const createManualProfileParticipantRespondents = async (
  body: ICreateManualRespondentsBody[],
  params: ICreateRespondentsParams,
  token: string,
  dispatch: Dispatch
): Promise<any> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.post(
      apiEndPoints.createManualProfileParticipantRespondents,
      JSON.stringify(body),
      {
        headers: auth,
        params: params,
      }
    )
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const sendRespondentsInvitation = async (
  body: IRespondentsInvitationBody[],
  languageCode: string,
  token: string,
  dispatch: Dispatch
): Promise<any> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.post(apiEndPoints.sendRespondentsInvitation, JSON.stringify(body), {
      headers: auth,
      params: { languageCode },
    })
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const deleteRespondent = async (
  id: number,
  token: string,
  dispatch: Dispatch
): Promise<any> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  const paramQuery = {
    id: id,
  }
  try {
    const response = await api.delete(apiEndPoints.deleteRespondent, {
      headers: auth,
      params: paramQuery,
    })
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const updateParticipantLanguage = async (
  languageCode: string,
  token: string,
  dispatch: Dispatch
): Promise<boolean> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.put(
      apiEndPoints.updateParticipantLanguage,
      {},
      {
        headers: auth,
        params: { languageCode },
      }
    )
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const canParticipantChangeLanguageWithoutEffect = async (
  token: string,
  dispatch: Dispatch
): Promise<any> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.post(
      apiEndPoints.canParticipantChangeLanguageWithoutEffect,
      {},
      {
        headers: auth,
      }
    )
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getParticipantPresentationByPresentationId = async (
  participantId: number,
  participantPresentationId: number,
  token: string,
  dispatch: Dispatch
): Promise<any> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.get(apiEndPoints.getParticipantPresentationByPresentationId, {
      headers: auth,
      params: { participantId, participantPresentationId },
    })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
