import { Dispatch } from 'redux'
import { api, apiEndPoints } from '../../api'
import { setNotification, setSpinner } from '../actions'
import { ApiResponse, ApiResult } from '@app/types'
import { ICulture, ILanguage } from '../commonInterfaces'

export const getLanguages = async (dispatch: Dispatch): Promise<ApiResult<ILanguage>> => {
  try {
    const response = await api.get(apiEndPoints.getLanguages)
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getAllLanguages = async (
  body: any,
  token: string,
  dispatch: Dispatch
): Promise<any> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.get(apiEndPoints.getAllLanguages, { headers: auth, params: body })
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const deleteLanguage = async (
  token: string,
  languageId: number,
  dispatch: Dispatch
): Promise<any> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  const paramQuery = {
    id: languageId,
  }
  try {
    await api.delete(apiEndPoints.deleteLanguage, {
      headers: auth,
      params: paramQuery,
    })
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getFacilitatorCultures = async (token: string, dispatch: Dispatch): Promise<any> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.get(apiEndPoints.getFacilitatorCultures, { headers: auth })
    if (response?.data?.result) {
      const cultures: ICulture[] = response.data.result.items
      return cultures.sort((a, b) =>
        a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())
      )
    }
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export type ImportExportTranslations = {
  schemaVersion: number
  rows: ReadonlyArray<unknown>
}

export function exportTranslations(
  token: string,
  dispatch: Dispatch
): Promise<ImportExportTranslations> {
  const auth = {
    Authorization: `Bearer ${token}`,
  }

  dispatch(setSpinner(true))

  return api
    .get<ApiResponse<ImportExportTranslations>>(apiEndPoints.exportLanguageTexts, { headers: auth })
    .then((res) => {
      if (!res.data.success) {
        return Promise.reject(res.data.error)
      }

      return res.data.result
    })
    .catch((e) => {
      dispatch(setNotification(e))
      return Promise.reject(e)
    })
    .finally(() => {
      dispatch(setSpinner(false))
    })
}

export function importTranslations(
  token: string,
  data: ImportExportTranslations,
  dispatch: Dispatch
): Promise<unknown> {
  const auth = {
    Authorization: `Bearer ${token}`,
  }

  dispatch(setSpinner(true))

  return api
    .post(apiEndPoints.importLanguageTexts, data, { headers: auth })
    .catch((err) => {
      dispatch(setNotification(err))
      return Promise.reject(err)
    })
    .finally(() => {
      dispatch(setSpinner(false))
    })
}
