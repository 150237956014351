import { BEARER_TOKEN } from '../constants'
import { routePath } from '../containers/routePaths'

export const getBearerToken = () => localStorage.getItem(BEARER_TOKEN)

export const setBearerToken = (token: string, seconds: number) => {
  const expires = new Date()
  expires.setTime(expires.getTime() + seconds * 1000)
  document.cookie = `${BEARER_TOKEN} = ${token};expires=${expires.toUTCString()};path=${
    routePath.signIn
  }`
}

export const isAuthenticated = () => {
  const token = getBearerToken()
  if (token) {
    return true
  }
  return false
}
