import { AnyAction, Dispatch } from 'redux'
import { api, apiEndPoints } from '../../../api'
import { setBearerToken } from '../../../commonUtils/auth'
import { setNotification, setSpinner, triggerLanguageChange } from '../../actions'
import {
  LOGGED_IN_USER_DATA,
  LOGGED_IN_USER_ID,
  LOGGED_IN_USER_DETAILS,
  SET_DEFAULT_CLIENT,
  PASSWORD_RESET_CODE,
  GET_SOCIAL_LOGIN_STATUS,
  CLEAR_DEFAULT_CLIENT,
  SET_LOGGED_IN_USER_ROLE,
  SET_USER_LANGUAGE,
  SET_ACCESS_TOKEN_SCOPES,
} from './constants'
import { LanguageCode, LanguageId, RoleEnum, Roles } from '../../commonEnums'
import { setParticipantProfileExists } from '../../participantPages/actions'
import { IUserProfile } from '@app/containers/commonInterfaces'
import { ApiResponse } from '@app/types'
import { IDefaultClient, IUserLanguage } from './reducer'
import { LOCAL_STORAGE_KEYS } from '@app/constants'

export interface IDispatch {
  (action: any): void
}

export const loggedInUserData = (payload: IUserProfile) => ({ type: LOGGED_IN_USER_DATA, payload })

export const loggedInUserId = (payload: number) => ({ type: LOGGED_IN_USER_ID, payload })

export const setPasswordResetCode = (payload: string) => ({ type: PASSWORD_RESET_CODE, payload })

export const loggedInUserDetails = (payload: Object) => ({ type: LOGGED_IN_USER_DETAILS, payload })

export const setDefaultClient = (payload: IDefaultClient) => ({ type: SET_DEFAULT_CLIENT, payload })

export const clearDefaultClient = () => ({ type: CLEAR_DEFAULT_CLIENT })

export const setSocialLoginStatus = (payload: string[]) => ({
  type: GET_SOCIAL_LOGIN_STATUS,
  payload,
})

export const setLoggedInUserRole = (payload: Roles) => ({
  type: SET_LOGGED_IN_USER_ROLE,
  payload,
})

interface IClearLoggedInUserRoleAction {
  type: typeof SET_LOGGED_IN_USER_ROLE
  payload: undefined
}
export const clearLoggedInUserRole = (): IClearLoggedInUserRoleAction => ({
  type: SET_LOGGED_IN_USER_ROLE,
  payload: undefined,
})

interface ISetUserLanguageAction {
  type: typeof SET_USER_LANGUAGE
  payload: IUserLanguage
}
export const setUserLanguage = (
  languageCode: string,
  languageId: number
): ISetUserLanguageAction => ({
  type: SET_USER_LANGUAGE,
  payload: {
    userLanguageCode: languageCode,
    userLanguageId: languageId,
  },
})

export function setAccessTokenScopes(scopes: ReadonlyArray<AccessTokenScope>) {
  window.localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_SCOPES, JSON.stringify(scopes))

  return {
    type: SET_ACCESS_TOKEN_SCOPES,
    payload: scopes,
  }
}

export type AccessTokenScope = {
  userId: number
  roleId: RoleEnum
  roleName: keyof typeof RoleEnum
  clientId: number | null
  clientName: string | null
  isDefaultClient: boolean
  token: string
  tokenIssuedAt: string
  tokenExpiresAt: string
}

export type AuthenticateResult = {
  accessTokens: Array<AccessTokenScope>
  emailConfirmed: boolean
  expireInSeconds: number
  passwordResetCode: string
  requiresTwoFactorVerification: boolean
  shouldResetPassword: string
  userId: number
}

export const loginUserAction = async (
  dispatch: Dispatch,
  user: { email: string; password: string },
  isParticipant: boolean
): Promise<ApiResponse<AuthenticateResult> | undefined> => {
  const person: Object = {
    userNameOrEmailAddress: user.email,
    password: user.password,
    isParticipant: isParticipant,
  }
  try {
    const response = await api
      .post<ApiResponse<AuthenticateResult>>(apiEndPoints.logIn, JSON.stringify(person))
      .then((res) => res)
      .catch((error) => {
        return Promise.reject(error)
      })

    if (!response.data.success) {
      return await Promise.reject(response.data)
    }

    const accessTokens = response.data.result.accessTokens
    const accessToken = accessTokens[0]?.token
    const userId = response.data.result.userId
    const passwordResetCode = response.data.result.passwordResetCode
    const expireInSeconds = response.data.result.expireInSeconds

    dispatch(loggedInUserId(userId))
    dispatch(setAccessTokenScopes(accessTokens))

    if (passwordResetCode) {
      dispatch(setPasswordResetCode(passwordResetCode))
    }
    if (expireInSeconds && accessToken) {
      setBearerToken(accessToken, expireInSeconds)
    }

    return response?.data
  } catch (error: any) {
    dispatch(setNotification(error))
  }
  return undefined
}

export type ExternalAuthenticateResult = {
  accessTokens: Array<AccessTokenScope>
  expireInSeconds: number
  waitingForActivation: boolean
  returnUrl: string
}

export const externalLogin = async (
  dispatch: Dispatch<AnyAction>,
  socialLoginData: {
    authProvider: string
    providerKey: string
    providerAccessToken: string
    providerCode: string
    returnUrl: string
    singleSignIn: boolean
  }
): Promise<ApiResponse<ExternalAuthenticateResult>> => {
  const body = {
    authProvider: socialLoginData.authProvider,
    providerKey: socialLoginData.providerKey,
    providerAccessToken: socialLoginData.providerAccessToken,
    providerCode: socialLoginData.providerCode,
    returnUrl: socialLoginData.returnUrl,
    singleSignIn: socialLoginData.singleSignIn,
  }

  try {
    const response = await api.post<ApiResponse<ExternalAuthenticateResult>>(
      apiEndPoints.externalAuthenticate,
      JSON.stringify(body)
    )
    if (response.data.success) {
      const accessTokens = response.data.result.accessTokens
      const accessToken = accessTokens[0]?.token
      const { expireInSeconds } = response.data.result
      setBearerToken(accessToken, expireInSeconds)
      dispatch(setAccessTokenScopes(accessTokens))
    }
    return response.data
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getLoggedInUserData = async (
  isParticipant: boolean,
  uToken: string,
  dispatch: IDispatch
): Promise<IUserProfile> => {
  const auth = {
    Authorization: `Bearer ${uToken}`,
  }
  const params = {
    isParticipant,
  }
  try {
    const response = await api.get<ApiResponse<IUserProfile>>(apiEndPoints.getCurrentUserProfile, {
      headers: auth,
      params,
    })
    if (!response.data.success) {
      return await Promise.reject(response.data)
    }
    dispatch(loggedInUserData(response.data.result))
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}

export const getUserDetails = async (
  token: string,
  dispatch: Dispatch,
  isParticipant: boolean,
  existingLangCode: string
): Promise<any> => {
  dispatch(setSpinner(true))
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.get(apiEndPoints.getUserDetails, {
      headers: auth,
      params: { isParticipant },
    })
    if (response.data.success) {
      dispatch(setSpinner(false))
      const userLanguageCode: string = response.data.result.userLanguageCode || LanguageCode.English
      const userLanguageId: number = response.data.result.userLanguageId || LanguageId.English

      if (existingLangCode !== userLanguageCode) {
        triggerLanguageChange(userLanguageCode, false, dispatch, userLanguageId)
      }

      dispatch(setUserLanguage(userLanguageCode, userLanguageId))
      dispatch(loggedInUserId(response.data.result.userId))

      // Setting the default client details
      if (response.data.result.defaultClientId) {
        const defaultClientId: number = response.data.result.defaultClientId ?? 0
        const defaultClientName: string = response.data.result.defaultClientName
        dispatch(
          setDefaultClient({
            defaultClientId,
            defaultClientName,
          })
        )
      }
    }
    const isAdmin = response.data.result.userRoles.includes(Roles.Admin)
    if (isAdmin) {
      dispatch(setLoggedInUserRole(Roles.Admin))
    } else dispatch(clearLoggedInUserRole())

    dispatch(loggedInUserDetails(response.data.result))
    dispatch(setParticipantProfileExists(response.data.result.isProfileExists ? true : false))

    return response.data.result
  } catch (error: any) {
    dispatch(setSpinner(false))
    dispatch(setNotification(error))
    throw error
  }
}

export const sendEmailForResetPassword = async (
  email: string,
  languageCode: string,
  dispatch: IDispatch
): Promise<any> => {
  const body = {
    emailAddress: email,
    languageCode,
  }
  try {
    const res = await api.post(apiEndPoints.sendResetPasswordCode, JSON.stringify(body))
    return res
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  } finally {
    dispatch(setSpinner(false))
  }
}
