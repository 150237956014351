import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { IResolveParams, LoginSocialGoogle, LoginSocialMicrosoft } from 'reactjs-social-login'
import { setNotification } from '../../../actions'
import { useRegisterPage } from './hooks'
import ValidationBox from '../../../../components/newPasswordInputBox/validationBox'
import { DropdownSelect } from '@app/components/formComponents/dropdownSelect'
import Input from '../../../../components/formComponents/input'
import { CustomModal } from '../../../../components/modals/customModal'
import { RegisterParticipantInputs } from './interface'
import { ExternalUserLogin } from '../externalUserLogin'
import { RootState } from '@app/store/configureStore'
import { Link, useNavigate } from 'react-router-dom'
import { AuthLayout } from '../layout'
import { routePath } from '@app/containers/routePaths'
import { pushToDataLayer } from '@app/commonUtils/google'
import IDIReCaptcha from '@app/components/reCaptcha/reCaptcha'

export const RegisterPage: React.FC<unknown> = (props) => {
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const {
    typeOfUsers,
    accountInfo,
    focusInput,
    countries,
    isCompanyFieldRequired,
    profile,
    provider,
    isUserExists,
    isUserRegistered,
    isUserSocialRegistered,
    setProvider,
    setProfile,
    recaptchaToken,
    handleInputChange,
    handleDropdownSelect,
    handleFocusEvent,
    handleSubmit,
    handleCloseUserExistsPopup,
    handleRecaptchaChange,
  } = useRegisterPage(languageText)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!recaptchaToken) {
      dispatch(setNotification('ReCaptcha not found'))
      return
    }

    pushToDataLayer({
      event: 'formSubmit',
      formType: 'registration',
      fields: {
        name: accountInfo.name,
        surname: accountInfo.surname,
        emailAddress: accountInfo.emailAddress,
        countries: accountInfo.country,
        typeOfUsers: accountInfo.plan,
      },
    })

    handleSubmit(event) // Pass the reCAPTCHA token to handleSubmit
  }

  const handleCaptchaToken = (token: string) => {
    handleRecaptchaChange(token)
  }

  return (
    <AuthLayout page='register' language={languageText}>
      {!isUserRegistered && (
        <div>
          <div className='mb-2 fs-4 fw-bolder'>
            {getLanguageValue(languageText, 'Create your IDI account')}
          </div>
          <div className='pb-2'>
            <span>{getLanguageValue(languageText, 'Already have an account?')} </span>
            <Link className='fw-bold' to={routePath.signIn}>
              {getLanguageValue(languageText, 'Sign in')}
            </Link>
          </div>
          <div className='border-top border-secondary-light mt-2'></div>
          <div className='pt-3'>{getLanguageValue(languageText, 'Register with')}</div>
          <div className='row mt-3 mb-4'>
            <div className='col-6'>
              <LoginSocialGoogle
                client_id={process.env.REACT_APP_GG_APP_ID || ''}
                redirect_uri={process.env.REDIRECT_URI}
                scope='openid profile email'
                onResolve={({ provider, data }: IResolveParams) => {
                  if (!data || (!data.access_token && !data.code)) {
                    dispatch(setNotification('User cancelled'))
                  } else {
                    setProvider(provider)
                    setProfile(data)
                    pushToDataLayer({
                      event: 'social_login',
                      provider: 'google',
                      userData: data,
                    })
                  }
                }}
                onReject={(error: any) => {
                  dispatch(setNotification('User cancelled'))
                }}
              >
                <button className='btn border border-secondary-subtle p-2 w-100'>
                  <i className='bi bi-google me-2'></i>
                  <span className='fw-bold'>Google</span>
                </button>
              </LoginSocialGoogle>
            </div>

            <div className='col-6'>
              <LoginSocialMicrosoft
                client_id={process.env.REACT_APP_MICROSOFT_APP_ID || ''}
                redirect_uri={process.env.REDIRECT_URI || ''}
                scope='profile openid email user.read'
                onResolve={({ provider, data }: IResolveParams) => {
                  setProvider(provider)
                  setProfile(data)
                  pushToDataLayer({
                    event: 'social_login',
                    provider: 'microsoft',
                    userData: data,
                  })
                }}
                onReject={(error: any) => {
                  dispatch(setNotification(error))
                  throw error
                }}
              >
                <button className='btn border border-secondary-subtle p-2 w-100'>
                  <i className='bi bi-microsoft me-2'></i>
                  <span className='fw-bold '>Microsoft</span>
                </button>
              </LoginSocialMicrosoft>
            </div>
          </div>
          <div className='border-top border-secondary-light mb-3'></div>

          <div className='py-3'>{getLanguageValue(languageText, 'Or register with email')}</div>

          <form onSubmit={handleFormSubmit}>
            <Input
              name={RegisterParticipantInputs.name}
              label={getLanguageValue(languageText, 'Firstname')}
              value={accountInfo.name}
              focusInput={focusInput.name}
              placeholder={getLanguageValue(languageText, 'Firstname')}
              required={true}
              handleFocusEvent={handleFocusEvent}
              handleInputChange={handleInputChange}
            />
            <Input
              name={RegisterParticipantInputs.surname}
              label={getLanguageValue(languageText, 'Lastname')}
              value={accountInfo.surname}
              focusInput={focusInput.surname}
              placeholder={getLanguageValue(languageText, 'Lastname')}
              required={true}
              handleFocusEvent={handleFocusEvent}
              handleInputChange={handleInputChange}
            />
            <Input
              name={RegisterParticipantInputs.emailAddress}
              label={getLanguageValue(languageText, 'Email')}
              value={accountInfo.emailAddress}
              focusInput={focusInput.emailAddress}
              placeholder={getLanguageValue(languageText, 'Email')}
              required={true}
              handleFocusEvent={handleFocusEvent}
              handleInputChange={handleInputChange}
            />
            {!(provider !== '' && profile !== '') && ( // Only show password and confirm password fields if social login is not used
              <>
                <div className='mb-3'>
                  <Input
                    name={RegisterParticipantInputs.password}
                    label={getLanguageValue(languageText, 'Password')}
                    placeholder={getLanguageValue(languageText, 'Password')}
                    value={accountInfo.password}
                    focusInput={focusInput.password}
                    handleFocusEvent={handleFocusEvent}
                    handleInputChange={handleInputChange}
                    required={!(provider !== '' && profile !== '')}
                    type='password'
                  />
                </div>

                <ValidationBox value={accountInfo.password} languageText={languageText} />
                <div className='mb-3'>
                  <Input
                    name={RegisterParticipantInputs.confirmPassword}
                    label={getLanguageValue(languageText, 'Confirm Password')}
                    placeholder={getLanguageValue(languageText, 'Confirm Password')}
                    type='password'
                    value={accountInfo.confirmPassword}
                    focusInput={focusInput.confirmPassword}
                    handleFocusEvent={handleFocusEvent}
                    handleInputChange={handleInputChange}
                    required={!(provider !== '' && profile !== '')}
                  />
                </div>
              </>
            )}

            <DropdownSelect
              name={RegisterParticipantInputs.country}
              label={getLanguageValue(languageText, 'Country')}
              defaultLabel={getLanguageValue(languageText, 'Select Country')}
              list={countries}
              value={accountInfo.country}
              focusInput={focusInput.country}
              handleDropdownSelect={handleDropdownSelect}
              searchOption
              required
            />
            <DropdownSelect
              name={RegisterParticipantInputs.plan}
              label={getLanguageValue(languageText, 'Choose plan How you intend to use IDI')}
              defaultLabel={getLanguageValue(languageText, 'Choose type')}
              list={typeOfUsers}
              value={accountInfo.plan}
              focusInput={focusInput.plan}
              handleDropdownSelect={handleDropdownSelect}
              required
            />
            {isCompanyFieldRequired && (
              <Input
                label={getLanguageValue(languageText, 'Company')}
                value={accountInfo.company}
                placeholder={getLanguageValue(languageText, 'Company')}
                name={RegisterParticipantInputs.company}
                focusInput={focusInput.company}
                handleFocusEvent={handleFocusEvent}
                handleInputChange={handleInputChange}
                required={true}
              />
            )}
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ''}>
              <IDIReCaptcha handleTokenChange={handleCaptchaToken}></IDIReCaptcha>
            </GoogleReCaptchaProvider>

            <button type='submit' className='btn btn-success w-100 mt-4'>
              {getLanguageValue(languageText, 'Register')}
            </button>
          </form>
        </div>
      )}

      {isUserExists && (
        <CustomModal
          headerText={getLanguageValue(languageText, 'Account Exists')}
          bodyText={`${getLanguageValue(
            languageText,
            'The account already exists. Do you want to login'
          )} ?`}
          cancelButtonText={getLanguageValue(languageText, 'No')}
          submitButtonText={getLanguageValue(languageText, 'Yes')}
          handleCancelClick={handleCloseUserExistsPopup}
          handleSubmitClick={() => {
            navigate(routePath.signIn)
          }}
        />
      )}

      {isUserRegistered && (
        <div className='h-400px py-auto px-10'>
          <p className='fs-4'>
            {getLanguageValue(
              languageText,
              'Thank you for registering. You may have got an activation email to activate your account.'
            )}
          </p>
        </div>
      )}

      {isUserSocialRegistered && <ExternalUserLogin provider={provider} profile={profile} />}
    </AuthLayout>
  )
}
