import { Dispatch } from 'redux'

import { api, apiEndPoints } from '../../../api'
import { setNotification, setSpinner } from '../../actions'
import { IUpdateLanguageParams, IUserProfile } from '@app/containers/commonInterfaces'

export const updateUser = async (
  body: IUserProfile,
  isParticipant: boolean,
  token: string,
  dispatch: Dispatch
): Promise<boolean> => {
  const queryParmas = {
    isParticipant,
  }
  const auth: { [key: string]: string } = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.put(apiEndPoints.editUserData, body, {
      headers: auth,
      params: queryParmas,
    })
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    dispatch(setSpinner(false))
    throw error
  }
}

export const updateUserLanguage = async (
  body: IUpdateLanguageParams,
  token: string,
  dispatch: Dispatch
): Promise<boolean> => {
  const auth = {
    Authorization: `Bearer ${token}`,
  }
  try {
    const response = await api.put(apiEndPoints.updateUserLanguage, JSON.stringify(body), {
      headers: auth,
    })
    return response.data.success
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
