import * as Redux from 'redux'
import { ModelType } from '../commonInterfaces'

type RatingState = {
  target: { modelType: ModelType; modelId: number; modelName: string } | undefined
}

type RatingAction =
  | {
      type: 'RATING__WANT_RATING_FOR_MODEL'
      modelType: ModelType
      modelId: number
      modelName: string
    }
  | { type: 'RATING__DISMISS_RATING' }

export function createWantRatingAction(type: ModelType, id: number, name: string): RatingAction {
  return {
    type: 'RATING__WANT_RATING_FOR_MODEL',
    modelType: type,
    modelId: id,
    modelName: name,
  }
}

export function createDismissRatingAction(): RatingAction {
  return {
    type: 'RATING__DISMISS_RATING',
  }
}

export const ratingReducer: Redux.Reducer<RatingState, RatingAction> = (state, action) => {
  if (!state) {
    return {
      target: undefined,
    }
  }
  switch (action.type) {
    case 'RATING__WANT_RATING_FOR_MODEL':
      // TODO: ratings are disabled until further notice. we need to figure out
      //   how we should ask for feedback from clients of clients, and if we're
      //   even allowed to do so.
      //   -johan, 2024-04-03
      console.info('Ratings are disabled.')
      return state

    /*
      return {
        target: {
          modelType: action.modelType,
          modelId: action.modelId,
          modelName: action.modelName,
        },
      };
      */
    case 'RATING__DISMISS_RATING':
      return {
        target: undefined,
      }
  }
  return state
}
