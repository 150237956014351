import React, { ReactNode } from 'react'
import clsx from 'clsx'
import {
  IPresentationSlide,
  IPresentationStepRule,
} from '@app/containers/facilitatorDelivery/hooks'

export const renderHTMLAcademy = (
  rawHTML: string,
  style?: string,
  paragraphClass?: string
): ReactNode => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: rawHTML.replace(
          /<p([^>]*)>/g,
          `<p class="${clsx(paragraphClass ? paragraphClass : 'fs-6')}"$1>`
        ),
      }}
      className={clsx('academy-content-container fs-6', style && style)}
    />
  )
}

// extracts all the possible props for a specific HTML tag.
// to get the props for a 'div' you can do this:
//
//   type DivProps = HTMLProps<'div'>
//
type HTMLProps<T> = T extends keyof React.ReactHTML
  ? React.ReactHTML[T] extends React.DetailedHTMLFactory<infer P, any>
    ? P
    : never
  : never

/**
 * Render static HTML into a <div /> tag and applies CSS rules defined
 * in the 'Presentation templates' view in the admin interface.
 *
 * @see https://github.com/IDI-Profiling/webclient/issues/1150
 */
export function unsafeRenderPresentationSlide(
  slide: IPresentationSlide,
  slideStepIndex: number | null,
  containerProps: HTMLProps<'div'>
): React.ReactElement {
  const viewport = document.createElement('div')
  viewport.innerHTML = slide.html

  if (slideStepIndex !== null) {
    // apply rules from all previous steps, including the current one.

    for (let i = 0; i <= slideStepIndex; ++i) {
      const step = slide.slideSteps[i]

      if (!step) {
        // FIXME: this is a bug. if we don't have any slide steps we should never set
        //   'slideStepIndex' to 0. instead, we should just move to the next slide.
        //
        //   another consequence of this bug is that we always require at least two
        //   clicks to get to the next slide, even if a slide doesn't have any steps.
        //
        //   -johan, 2024-04-08
        continue
      }

      const rules = step.stepRules ?? []

      for (const rule of rules) {
        const matches = viewport.getElementsByClassName(rule.selectionClass)

        for (let j = 0; j < matches.length; ++j) {
          const el = matches[j]
          switch (rule.action) {
            case 'add':
              el.classList.add(rule.actionClass)
              break
            case 'remove':
              el.classList.remove(rule.actionClass)
              break
          }
        }
      }
    }
  }

  return React.createElement('div', {
    ...containerProps,
    dangerouslySetInnerHTML: {
      __html: viewport.innerHTML,
    },
  })
}
