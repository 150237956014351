import React from 'react'
import { Link } from 'react-router-dom'
import { useVerifyEmail } from './hooks'
import { AuthLayout } from '../auth/signUp/layout'
import { RootState } from '@app/store/configureStore'
import { useSelector } from 'react-redux'
import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'

export const VerifyEmail: React.FC = (props) => {
  const { verifyResponse, isEmailVerified } = useVerifyEmail()
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  return (
    <React.Fragment>
      <AuthLayout page='register' language={languageText}>
        <p className='fs-4'>{verifyResponse}</p>

        {isEmailVerified && (
          <Link to='/' className='d-flex justify-content-center'>
            <button type='submit' className='btn btn-success' data-kt-users-modal-action='submit'>
              {getLanguageValue(languageText, 'Login')}
            </button>
          </Link>
        )}
      </AuthLayout>
    </React.Fragment>
  )
}
