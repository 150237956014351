import * as React from 'react'
import { AlertAction, IAlert } from './types'
import { ModalComponent } from '@app/components/modals/modalComponent'
import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@app/store/configureStore'
import { updateAlertWithActionTaken } from '../actions'
import { takeAlertAction } from './actions'
import { getCookieValue } from '../rootState'
import { BEARER_TOKEN } from '@app/constants'

type Props = {
  alert: IAlert
}

export const InteractAlertModal: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const lang = useSelector((state: RootState) => state.mainReducer.languageText)
  const token = getCookieValue(BEARER_TOKEN)

  function takeAlertActionClick(
    event: React.MouseEvent<HTMLElement>,
    action: AlertAction
  ): Promise<unknown> {
    event.preventDefault()
    event.stopPropagation()

    return takeAlertAction(token, dispatch, props.alert.id!, action).then(() => {
      dispatch(updateAlertWithActionTaken(props.alert.trigger, action))
    })
  }

  return (
    <ModalComponent
      headerText={props.alert.title}
      submitButtonText={getLanguageValue(lang, 'OK')}
      handleSubmitClick={(event) => {
        takeAlertActionClick(event, AlertAction.Accept)
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: props.alert.body }} />
    </ModalComponent>
  )
}
