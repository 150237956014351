import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { clearNotifications, setSpinner } from '../actions'
import { verifyEmail } from './actions'

export interface IVerifyEmailBody {
  c: string
}

export const useVerifyEmail = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const urlParams: IVerifyEmailBody = {
    c: searchParams.get('c') ?? '',
  }

  const [verifyResponse, setVerifyResponse] = useState<string>('')
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false)

  useEffect(() => {
    dispatch(setSpinner(true))
    dispatch(clearNotifications(''))
    verifyEmail(urlParams, dispatch)
      .then((response) => {
        if (response) {
          setVerifyResponse('Your email is now verified')
          setIsEmailVerified(true)
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }, [])

  return {
    verifyResponse,
    isEmailVerified,
  }
}
