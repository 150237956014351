import { SET_RESPONDENT_CODE, SET_RESPONDENT_DATA } from './constant'

export interface IRespondentData {
  languageName: string
  languageId: number
  emailAddress: string
  isFormFilledData: boolean
  isFormFilled: boolean
  profileParticipantId: number
  profileRespondentId: number
  participantName: string
  isProfileDelivered: boolean
}

export interface IInitialState {
  respondentCode: string
  respondentData: IRespondentData
}

const initialState: IInitialState = {
  respondentCode: '',
  respondentData: {
    languageName: '',
    languageId: 0,
    emailAddress: '',
    isFormFilledData: false,
    isFormFilled: false,
    profileParticipantId: 0,
    profileRespondentId: 0,
    participantName: '',
    isProfileDelivered: false,
  },
}

const respondentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_RESPONDENT_CODE:
      return {
        ...state,
        respondentCode: action.payload,
      }
    case SET_RESPONDENT_DATA:
      return {
        ...state,
        respondentData: action.payload,
      }
    default:
      return state
  }
}

export default respondentReducer
