import { ITranslationObject, getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { ISliderDataSteps } from './slider'
import DataTransfer from '../../../public/DeliveryImages/DataTransfer.svg'

const sliderDataSteps = (languageText: ITranslationObject): ISliderDataSteps[] => [
  {
    id: 1,
    img: DataTransfer,
    header: getLanguageValue(languageText, 'Intentions vs actions'),
    text: getLanguageValue(languageText, 'Get insight into how others perceive you'),
  },
  {
    id: 2,
    img: DataTransfer,
    header: getLanguageValue(languageText, 'Slide 2 Header'),
    text: getLanguageValue(languageText, 'Slide 2 Text'),
  },
  {
    id: 3,
    img: DataTransfer,
    header: getLanguageValue(languageText, 'Slide 3 Header'),
    text: getLanguageValue(languageText, 'Slide 3 Text'),
  },
  {
    id: 4,
    img: DataTransfer,
    header: getLanguageValue(languageText, 'Slide 4 Header'),
    text: getLanguageValue(languageText, 'Slide 4 Text'),
  },
]

export { sliderDataSteps }
