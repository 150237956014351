import React, { useState, useCallback, useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

interface IDIReCaptchaProps {
  handleTokenChange: (token: string) => void
}

const IDIReCaptcha: React.FC<IDIReCaptchaProps> = ({ handleTokenChange }) => {
  const [dynamicAction, setDynamicAction] = useState('registerpage')
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha || !dynamicAction) {
      return
    }

    const token = await executeRecaptcha('dynamicAction')
    handleTokenChange(token)
  }, [dynamicAction, executeRecaptcha])

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify()
  }, [dynamicAction, executeRecaptcha])

  return <div></div>
}

export default IDIReCaptcha
