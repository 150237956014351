import React from 'react'
import clsx from 'clsx'

import { IFocusError } from './input'

interface ITeaxtAreaProps {
  name?: string
  label?: string
  value?: string
  placeholder?: string
  areaClass?: string
  focusInput?: IFocusError
  checkMarkNotRequired?: boolean
  disable?: boolean
  required?: boolean
  maxLength?: number
  containerClass?: string
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  handleFocusEvent?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  rows?: number
}

const TextArea = (props: ITeaxtAreaProps): JSX.Element => {
  const {
    name,
    label,
    value,
    placeholder,
    areaClass,
    disable,
    focusInput,
    checkMarkNotRequired,
    required,
    maxLength = 0,
    containerClass,
    handleFocusEvent,
    onChange,
  } = props

  return (
    <>
      <div className={clsx('mb-3', containerClass && containerClass)}>
        {label && <label className={clsx('fs-6 mb-2', { required: required })}>{label}</label>}
        <textarea
          className={clsx(
            areaClass ? areaClass : 'form-control form-control-solid min-h-100px',
            {
              'is-invalid': focusInput?.touched && focusInput?.errorMessage,
            },
            {
              'is-valid':
                !focusInput?.errorMessage && focusInput?.touched && value && !checkMarkNotRequired,
            }
          )}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={handleFocusEvent}
          maxLength={maxLength ? maxLength : undefined}
          disabled={disable ?? false}
          rows={props.rows}
        />

        {focusInput?.errorMessage && (
          <div className='invalid-feedback d-inline-block'>{focusInput.errorMessage}</div>
        )}
      </div>

      {maxLength > 0 && (
        <>
          <div className='d-flex justify-content-end'>
            <div className='border bg-success rounded text-white d-flex align-items-center p-2 mt-n2'>
              {maxLength - value!.length}/{maxLength}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default TextArea
