import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HubConnectionBuilder } from '@microsoft/signalr'

import {
  getAllPresentationSlides,
  UpdateParticipantScreen,
  updateCoursePresentationStatus,
  getPresentationStatus,
  getAllParticipants,
  deliverProfileParticipants,
  getParticipantCurrentSlide,
  getFacilitatorPresentation,
} from './actions'
import { getCookieValue } from '../rootState'
import { RootState } from '@app/store/configureStore'
import { setNotification, setSpinner } from '../actions'
import { CoursePresentationStatus } from '../profileList/profileEnums'
import { BEARER_TOKEN } from '../../constants'
import { unsafeRenderPresentationSlide } from '@app/commonUtils/renderHtmlHelper'
import { pIdQueryKey } from '@app/consts'

export interface IParticipantCurrentSlideParams {
  slideId: number
  participantId: number
  languageCode: string
}

export interface IDeliverProfileParticipantsParams {
  courseId: number
  coursePresentationId: number
  finishedMsg: string
  languageCode: string
}

export interface IPresentationStepRule {
  selectionClass: string
  action: 'add' | 'remove'
  actionClass: string
}

interface IPresentationSlideSteps {
  step: string
  stepRules: IPresentationStepRule[]
}

export interface IPresentationSlide {
  id: number
  description: string
  html: string
  slideNumber: number
  slideSteps: IPresentationSlideSteps[]
  notes: string
}

export interface IParticipants {
  profileParticipantId: number
  courseId: number
  forename: string
  surname: string
  email: string
  participantId: number
  presentation: number
  presentationId: number
  checked: boolean
  isOnline: boolean
}

interface IParticipantsStatus {
  id: number
  isOnline: boolean
}

interface IPresentationData {
  presentationId: number
  templateId: number
  profileId: number
  facilitatorName: string
  languageCode: string
}

export function renderSlideHTML(
  slide: IPresentationSlide,
  step: number | null
): React.ReactElement {
  return unsafeRenderPresentationSlide(slide, step, {
    className: '',
  })
}

/**
 * @TODO there's nothing 'dynamic' about this function at all. it just injects
 *       a different class name into the containing <div> tag. maybe we can give
 *       it a better name, or stop using the 'dynamic' monicer alltogether. the
 *       data isn't dynamic, it's just templated HTML.
 *
 * @see renderSlideHTML
 */
export function renderSlideDynamicHTML(
  slide: IPresentationSlide,
  step: number | null
): React.ReactElement {
  return unsafeRenderPresentationSlide(slide, step, {
    className: '',
  })
}

export const useFacilitatorDelivery = () => {
  const dispatch = useDispatch()
  let facilitatorToken = getCookieValue(BEARER_TOKEN)

  const queryParams = new URLSearchParams(location.search)
  const presentationPublicId = queryParams.get(pIdQueryKey) || ''

  const facilitatorSlides: IPresentationSlide[] = useSelector(
    (state: RootState) => state.facilitatorDeliveryReducer.presentationSlides
  )
  const participantsList = useSelector(
    (state: RootState) => state.facilitatorDeliveryReducer.participantsList
  )
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const initialPresentationDataState: IPresentationData = {
    presentationId: 0,
    templateId: 0,
    profileId: 0,
    facilitatorName: '',
    languageCode: '',
  }
  const initialSlideState: IPresentationSlide = {
    id: 0,
    description: '',
    html: '',
    slideNumber: 0,
    slideSteps: [],
    notes: '',
  }
  const initialParticipantsStatusState: IParticipantsStatus = {
    id: 0,
    isOnline: false,
  }
  const [presentationData, setPresentationData] = useState<IPresentationData>(
    initialPresentationDataState
  )
  const [currentSlide, setCurrentSlide] = useState<IPresentationSlide>(initialSlideState)
  const [carouselSlideIndex, setCarouselSlideIndex] = useState<number>(0)
  const [participants, setParticipants] = useState<IParticipants[]>([])
  const [participantsStatus, setParticipantsStatus] = useState<IParticipantsStatus>(
    initialParticipantsStatusState
  )
  const [slideStep, setSlideStep] = useState<number | null>(null)
  const [startPresentationPage, setStartPresentationPage] = useState(true)
  const [cancelPresentationModal, setCancelPresentationModal] = useState(false)
  const [finalPage, setFinalPage] = useState(false)
  const [participantPublished, setParticipantPublished] = useState(false)
  const [participantDynamicSlideModal, setParticipantDynamicSlideModal] = useState(false)
  const [participantDynamicSlide, setParticipantDynamicSlide] =
    useState<IPresentationSlide>(initialSlideState)

  useEffect(() => {
    if (presentationPublicId && !presentationData.presentationId) {
      getFacilitatorPresentation(presentationPublicId, facilitatorToken, dispatch).then(
        (response) => {
          if (response) {
            setPresentationData({
              presentationId: response.coursePresentationId,
              templateId: response.templateId,
              profileId: response.profileId,
              facilitatorName: response.facilitatorName,
              languageCode: response.languageCode,
            })
          }
        }
      )
    }
  }, [presentationPublicId])

  // signalr-connection
  useEffect(() => {
    if (presentationData.presentationId) {
      const connection = new HubConnectionBuilder()
        .withUrl(
          `${process.env.REACT_APP_BASE_URL}signalr-delivery/?cpid=${presentationData.presentationId}`
        )
        .withAutomaticReconnect()
        .build()

      connection.on('getParticipantOnlineStatus', (participantId: number, status: boolean) => {
        setParticipantsStatus({
          id: participantId,
          isOnline: status,
        })
      })

      connection
        .start()
        .then((result) => {})
        .catch((e) => {
          dispatch(setNotification(e))
          return Promise.reject(e)
        })
    }
  }, [presentationData.presentationId])

  // Presentation Details
  useEffect(() => {
    const getPresentationDetails = async (): Promise<any> => {
      dispatch(setSpinner(true))
      // Get presentation slides
      await getAllPresentationSlides(presentationData.templateId, facilitatorToken, dispatch)

      // Get presentation status
      await getPresentationStatus(presentationData.presentationId, facilitatorToken, dispatch).then(
        (response) => {
          if (response?.status) {
            if (response.status !== CoursePresentationStatus.Planned)
              setStartPresentationPage(false)
          }
        }
      )

      // Get presentation participants
      await getAllParticipants(
        presentationData.profileId,
        presentationData.presentationId,
        facilitatorToken,
        dispatch
      )
    }

    if (presentationData.presentationId) {
      getPresentationDetails().finally(() => dispatch(setSpinner(false)))
    }
  }, [presentationData.presentationId])

  // Facilitator Slides
  useEffect(() => {
    facilitatorSlides.length > 0 && setCurrentSlide(facilitatorSlides[carouselSlideIndex])
    setCarouselSlideIndex(0)
  }, [facilitatorSlides])

  // Participants List
  useEffect(() => {
    const updatedParticipants = [...participantsList]
    updatedParticipants.forEach((participant) => {
      participant.checked = false
      participant.isOnline = false
    })
    setParticipants(updatedParticipants)
  }, [participantsList])

  // Participants Status
  useEffect(() => {
    const updatedParticipants = [...participants]

    const participantIndex = updatedParticipants.findIndex(
      (participant) => participant.profileParticipantId === Number(participantsStatus.id)
    )

    if (updatedParticipants[participantIndex]) {
      updatedParticipants[participantIndex].isOnline = participantsStatus.isOnline
      setParticipants(updatedParticipants)
    }
  }, [participantsStatus])

  // Update Screen/Slide
  useEffect(() => {
    if (Number.isNaN(slideStep)) {
      setSlideStep(null)
    }

    if (currentSlide?.id) {
      UpdateParticipantScreen(
        presentationData.presentationId,
        currentSlide.id,
        slideStep,
        facilitatorToken,
        dispatch
      )
    }
  }, [presentationData.presentationId, currentSlide, slideStep])

  const startPresentation = async (): Promise<void> => {
    dispatch(setSpinner(true))
    //Here we need to send email in participant language but this is facilitator language. Still we are passing empty string as the API requires this field for ASP purpose.
    updateCoursePresentationStatus(
      presentationData.presentationId,
      '',
      CoursePresentationStatus.Ongoing,
      facilitatorToken,
      dispatch
    )
      .then((reponse) => reponse && setStartPresentationPage(false))
      .finally(() => dispatch(setSpinner(false)))
  }

  const previousSlide = (): void => {
    // To check if the current slide has steps and the previous step is the first step and move to that previous step
    if (slideStep === 0) {
      setSlideStep(null)
      return
    }

    // Check if the current slide has steps and the current step is not the first step and move to the previous step
    if (slideStep !== null && slideStep > 0) {
      setSlideStep(slideStep - 1)
      return
    }

    // Check if the current slide was the final page and we are moving back to the previous slide
    if (carouselSlideIndex === 0 || finalPage) {
      setFinalPage(false)
      return
    } else {
      // This else condition is to move to the previous slide
      setCurrentSlide(facilitatorSlides[carouselSlideIndex - 1])
      setCarouselSlideIndex(carouselSlideIndex - 1)
      if (facilitatorSlides[facilitatorSlides.indexOf(currentSlide) - 1]?.slideSteps !== null) {
        setSlideStep(
          facilitatorSlides[facilitatorSlides.indexOf(currentSlide) - 1]?.slideSteps.length - 1
        )
      }
    }
  }

  const nextSlide = (): void => {
    const updatedSlideStepIndex = Number(slideStep) // Here slideStep is the currently visible slide step

    // This condition is to check if the current slide has steps and the slide step is null (means this current slide is new slide)
    // which was set in the last condition of this function while changing the slide
    if (
      currentSlide.slideSteps !== null &&
      currentSlide.slideSteps.length > 0 &&
      slideStep === null
    ) {
      setSlideStep(0)
      return
    }

    // To check if the current slide has steps and the current step is not the last step and move to the next step
    if (
      currentSlide.slideSteps !== null &&
      currentSlide.slideSteps.length - 1 > updatedSlideStepIndex
    ) {
      setSlideStep(updatedSlideStepIndex + 1)
      return
    }

    // To check if the next slide which we want to move is the final slide
    if (carouselSlideIndex === facilitatorSlides.length - 1) {
      setFinalPage(true)
      return
    } else {
      // This else condition is to move to the next slide
      setCurrentSlide(facilitatorSlides[carouselSlideIndex + 1])
      setCarouselSlideIndex(carouselSlideIndex + 1)
      setSlideStep(null)
    }
  }

  const handleSlideClick = (slideNumber: number): void => {
    const slideId = facilitatorSlides.findIndex((slide) => slide.slideNumber === slideNumber)
    setCurrentSlide(facilitatorSlides[slideId])
    setCarouselSlideIndex(slideId)
  }

  const handleCancelClick = (): void => setCancelPresentationModal(true)

  const handleCloseModal = (): void => setCancelPresentationModal(false)

  const handleConfirmCancelPresentation = (): void => {
    // Close the current tab
    window.close()
  }

  const handleSelectAllParticipantsForDelivery = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.target
    setParticipants((prev) => {
      const updatedParticipants = prev.map((participant) => ({
        ...participant,
        checked: checked,
      }))
      return updatedParticipants
    })
  }

  const handleSelectParticipantForDelivery = (
    e: React.ChangeEvent<HTMLInputElement>,
    profileParticipantId: number
  ): void => {
    const { checked } = e.target

    setParticipants((prev) => {
      const updatedParticipants = prev.map((participant) => {
        if (participant.profileParticipantId === profileParticipantId) {
          return { ...participant, checked: checked }
        }
        return participant
      })
      return updatedParticipants
    })
  }

  const handleFinalPageBackBtn = (): void => setFinalPage(false)

  const publishParticipants = (): void => {
    dispatch(setSpinner(true))
    const deliveryParticipants: number[] = []
    participants.forEach((participant) => {
      if (participant.checked === true) {
        deliveryParticipants.push(participant.profileParticipantId)
      }
    })

    //Here we need to send email in participant language but this is facilitator language. Still we are passing empty string as the API requires this field for ASP purpose.
    const params: IDeliverProfileParticipantsParams = {
      courseId: presentationData.profileId,
      coursePresentationId: presentationData.presentationId,
      finishedMsg: 'Finished',
      languageCode: '',
    }

    deliverProfileParticipants(deliveryParticipants, params, facilitatorToken, dispatch)
      .then((response) => {
        if (response) {
          setParticipantPublished(true)

          // Close the current tab
          setTimeout(() => {
            window.close()
          }, 5000)
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  const openParticipantDynamicSlides = (profileParticipantId: number): void => {
    const queryParmas: IParticipantCurrentSlideParams = {
      slideId: currentSlide.id,
      participantId: profileParticipantId,
      languageCode: presentationData.languageCode,
    }

    getParticipantCurrentSlide(queryParmas, facilitatorToken, dispatch).then((res) => {
      if (res?.status === 200) {
        setParticipantDynamicSlideModal(true)
        setParticipantDynamicSlide(res.data.result)
      }
    })
  }

  const closeParticipantDynamicSlides = (): void => {
    setParticipantDynamicSlideModal(false)
    setParticipantDynamicSlide(initialSlideState)
  }

  return {
    languageText,
    slideStep,
    currentSlide,
    facilitatorSlides,
    participants,
    presentationData,
    finalPage,
    startPresentationPage,
    cancelPresentationModal,
    participantPublished,
    participantDynamicSlideModal,
    participantDynamicSlide,
    startPresentation,
    previousSlide,
    nextSlide,
    handleSlideClick,
    handleCancelClick,
    openParticipantDynamicSlides,
    closeParticipantDynamicSlides,
    handleCloseModal,
    handleConfirmCancelPresentation,
    publishParticipants,
    handleSelectAllParticipantsForDelivery,
    handleSelectParticipantForDelivery,
    handleFinalPageBackBtn,
  }
}
