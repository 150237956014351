import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@app/store/configureStore'
import { getParticipantProfileInfo } from '@app/commonUtils/participantProfileHelper'
import { BEARER_TOKEN } from '@app/constants'
import { getCookieValue } from '../rootState'
import { routePath } from '../routePaths'

export const ucQueryKey = 'uc'

interface IUseUniqueCode {
  uniqueCode: string
}

const useUniqueCode = (): IUseUniqueCode => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const token = getCookieValue(BEARER_TOKEN)

  const queryParams = new URLSearchParams(location.search)
  const uniqueCode = queryParams.get(ucQueryKey) || ''

  const participantProfileDetails = useSelector(
    (state: RootState) => state.participantReducer.participantProfileDetails
  )

  useEffect(() => {
    if (!uniqueCode) return navigate(routePath.signIn)
    if (uniqueCode && token && !participantProfileDetails.profileParticipantId) {
      getParticipantProfileInfo(uniqueCode, participantProfileDetails, token, dispatch)
    }
  }, [])

  return { uniqueCode }
}

export default useUniqueCode
