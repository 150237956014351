import { Dispatch } from 'redux'

import { api, apiEndPoints } from '../../../../api'
import { setNotification } from '../../../actions'
import { IRegisterParticipantPayload, IRegisterParticipantResponse } from './interface'

export const registerNewUser = async (
  body: IRegisterParticipantPayload,
  dispatch: Dispatch
): Promise<IRegisterParticipantResponse> => {
  try {
    const response = await api.post(apiEndPoints.registerParticipant, JSON.stringify(body))
    return response.data.result
  } catch (error: any) {
    dispatch(setNotification(error))
    throw error
  }
}
