type GoogleTagManagerWindow = typeof window & {
  dataLayer: {
    push: (data: unknown) => void
  }
}

// TODO: this should probably be a strictly typed union for type safety.
type EventLike = {
  event: string
}

export function pushToDataLayer<T extends EventLike>(event: T): void {
  // our config file has a variable called 'REACT_APP_ENV' that infamously
  // has the environment name capitalized.
  if (process.env.NODE_ENV?.toLowerCase() !== 'production') {
    console.warn(`Environment is '${process.env.NODE_ENV}', ignoring Google Tag Manager push.`)
    return
  }
  ;(window as GoogleTagManagerWindow).dataLayer.push(event)
}
