import '@app/css/idi-variables.scss'
import '@app/css/idi-bootstrap.scss'
import '@app/css/idi-extension.scss'
import 'bootstrap-icons/font/bootstrap-icons.css'
import '@app/css/components/famfamfam_flags.scss'

import * as React from 'react'
import App from '../src/containers/App'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { configureAppStore } from '@app/store/configureStore'
import * as Sentry from '@sentry/react'

type IDIWindow = typeof window & {
  BUILD_VERSION: string
  BUILD_DATE: string
  RUNTIME_ENV: string
}

if (process.env.ENABLE_SENTRY) {
  Sentry.init({
    dsn: 'https://4c7d2d5129d5876516c0ce3454263e69@o4507017108783104.ingest.us.sentry.io/4507021616283648',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/dev\.idi\.se\/api/,
      /^https:\/\/platform\.idi\.se\/api/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
  Sentry.getCurrentScope().setTag('runtime_env', (window as IDIWindow).RUNTIME_ENV)
}

const store = configureAppStore()

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
