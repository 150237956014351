import { configureStore } from '@reduxjs/toolkit'
import { createReducer } from '../reducers'

export function configureAppStore() {
  const store = configureStore({
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }),
    ],
    devTools: process.env.REACT_APP_ENV !== 'Production',
  })

  return store
}

export type AppDispatch = ReturnType<typeof configureAppStore>['dispatch']
export type RootState = ReturnType<ReturnType<typeof configureAppStore>['getState']>
